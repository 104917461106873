export default class Event {
  static INITIALIZED = 'initialized';

  static INITIALIZATION_FAILED = 'initializationFailed';

  static SUBSCRIBED = 'subscribed';

  static UNSUBSCRIBED = 'unsubscribed';

  static OPTIN_SHOWN = 'optInShown';

  static OPTIN_CLOSED = 'optInClosed';

  static OPTIN_DENIED = 'optInDenied';

  static OPTIN_ALLOWED = 'optInAllowed';

  static PERMISSION_GRANTED = 'permissionGranted';

  static TOPICS_SET = 'topicsSet';

  static TOPICS_MANUALLY_SAVED = 'topicsManuallySaved';

  static TOPICS_LAYER_SHOWN = 'topicsLayerShown';

  static TOPICS_LAYER_DENIED = 'topicsLayerDenied';

  static TOPICS_LAYER_ALLOWED = 'topicsLayerAllowed';

  static TAGS_SET = 'tagsSet';

  static BELL_READY = 'bellReady';

  static BELL_CLICKED = 'bellClicked';

  static PERMISSION_RE_GRANTED = 'permissionReGranted';

  static PANEL_SHOWN = 'panelShown';

  static PANEL_HIDDEN = 'panelHidden';

  static CONFIRM_AVAILABLE = 'confirmAvailable';

  static BANNER_SHOWN = 'bannerShown';

  static BANNER_CLICKED = 'bannerClicked';

  static WIDGET_SHOWN = 'widgetShown';

  static CONFIG_AVAILABLE = 'configAvailable';

  static EVENT_TRACKED = 'eventTracked';
}
