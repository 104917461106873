export const ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  STAGE: 'stage',
};

export const CLEVERPUSH_API_ENDPOINTS = {
  DEVELOPMENT: 'http://localhost:4000',
  PRODUCTION: 'https://api.cleverpush.com',
  STAGE: 'https://api-stage.cleverpush.com',
};

export function getApiEndpoint() {
  try {
    if (ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT) {
      return window.apiEndpoint || CLEVERPUSH_API_ENDPOINTS.DEVELOPMENT;
    }

    if (ENVIRONMENT === ENVIRONMENTS.STAGE) {
      return window.apiEndpoint || CLEVERPUSH_API_ENDPOINTS.STAGE;
    }

    return CLEVERPUSH_API_ENDPOINTS.PRODUCTION;
  } catch (error) {
    return CLEVERPUSH_API_ENDPOINTS.PRODUCTION;
  }
}
