export default {
  en: {
    'confirm.title': '{0} wants to send you push notifications.',
    'confirm.nativeTitle': '{0} wants to send you notifications',
    'confirm.swiftAlertTitle': 'Enable push notfications',
    'confirm.info': 'Notifications can be turned off anytime from browser settings.',
    'confirm.allow': 'Allow',
    'confirm.allowChrome': 'Allow',
    'confirm.allowFirefox': 'Allow Notifications',
    'confirm.deny': 'Deny',
    'confirm.selectTopics': 'Which topics are you interested in?',
    'confirm.doubleText': 'Please click on {0} 2x to continue',
    'confirm.alreadySubscribed': 'You have already subscribed to all topics.',
    'confirm.silentPromptInfo': 'Please click on the small bell in the address bar at the top and then on "Allow" to continue.',
    'confirm.more': 'More…',
    'popup.info': 'Click on {0} to confirm push notifications',
    'popup.text': 'Get updates and offers from {0} by subscribing to push notifications.',
    'unsubscribe.title': 'Unsubscribe from notifications',
    'unsubscribe.confirm': 'Are you sure?',
    'unsubscribe.yes': 'Yes',
    'unsubscribe.no': 'No',
    'unsubscribe.feedback': 'Please choose the unsubscribe reason to complete your unsubscription:',
    'unsubscribe.enterReason': 'Please enter the reason:',
    'unsubscribe.submit': 'Submit',
    'survey.submit': 'Submit',
    'unsubscribe.reasonQuantity': 'Too many messages',
    'unsubscribe.reasonContent': 'Not interesting messages',
    'unsubscribe.reasonOther': 'Other reason',
    'windowclose.confirm': 'The process is not yet complete, are you sure you want to cancel it?',
    'deny.alertHttps': 'If you change your mind, click the lock to give your browser permission to send you notifications.',
    'deny.alertHttp': 'If you change your mind, click the info symbol to give your browser permission to send you notifications.',
    'bell.subscribe': 'Subscribe for notifications',
    'bell.subscribed': 'You\'re subscribed for notifications',
    'bell.unsubscribeSuccess': 'You won\'t receive notifications again',
    'bell.hide': 'Do not show again',
    'panel.notifications': 'Notifications',
    'panel.editTopics': 'Edit Topics',
    'panel.settings': 'Settings',
    'panel.notificationsEmpty': 'No notifications available.',
    'panel.save': 'Save',
    'panel.close': 'Close',
    'panel.successfullySaved': 'Successfully saved!',
    'unblock.title': 'You have blocked notifications.<br />Please follow these steps to receive notifications',
    privacy: 'Privacy',
    'chat.emailAddress': 'Email address',
    'chat.name': 'Your name',
    'chat.emailError': 'Please enter a valid email address.',
    'chat.start': 'Start chat',
    'chat.enable': 'Enable chat now',
    'chat.enterMessage': 'Please enter message',
    'chat.today': 'Today',
    'chat.yesterday': 'Yesterday',
    'chat.dayBefore': 'Day before yesterday',
    'chat.sunday': 'Sunday',
    'chat.monday': 'Monday',
    'chat.tuesday': 'Tuesday',
    'chat.wednesday': 'Wednesday',
    'chat.thursday': 'Thursday',
    'chat.friday': 'Friday',
    'chat.saturday': 'Saturday',
    'chat.the': 'the',
    'alert.unsupported': 'Unfortunately, your browser does not support notifications.',
    'voucherpool.title': 'Your voucher code',
    'voucherpool.text': 'Click here to copy your code',
    'voucher.code.copied': 'Voucher code was copied to the clipboard!',
    'story.overlay.text': 'Open story',
    'alert.checkboxRequired': 'Please tick at least one box to continue',
    'alert.unexpected': 'An unexpected error has occurred. Please try again later.',
    'alert.successEmailSend': 'We send the email with this email. Please check your email',
    'alert.enterTextAnswer': 'Please enter answer',
    'widget.topics': 'Topics',
    'widget.tags': 'Tags',
    'next.survey': 'Next Survey',
    'next.surveyQuestion': 'Next',
    'enterTextAnswer.survey': 'Enter your Answer'
  },
  de: {
    'confirm.title': '{0} würde dir gerne Push Nachrichten senden.',
    'confirm.nativeTitle': '{0} möchte Ihnen Benachrichtigungen senden',
    'confirm.swiftAlertTitle': 'Push Nachrichten aktivieren',
    'confirm.info': 'Benachrichtigungen können jederzeit in den Browser Einstellungen deaktiviert werden.',
    'confirm.allow': 'Erlauben',
    'confirm.allowChrome': 'Zulassen',
    'confirm.allowFirefox': 'Benachrichtigungen erlauben',
    'confirm.deny': 'Verbieten',
    'confirm.selectTopics': 'Welche Themen interessieren Dich?',
    'confirm.doubleText': 'Bitte bestätige 2x mit {0} um fortzufahren',
    'confirm.alreadySubscribed': 'Du hast dich bereits für alle Themen angemeldet.',
    'confirm.silentPromptInfo': 'Bitte klicke auf die kleine Glocke oben in der Adresszeile und dann auf "Erlauben" um fortzufahren.',
    'confirm.more': 'Mehr…',
    'popup.info': 'Klicke auf {0}, um unsere Push-Benachrichtigungen zu bestätigen',
    'popup.text': 'Erhalte Updates und Angebote von {0}, indem du Push-Benachrichtigungen abonnierst.',
    'unsubscribe.title': 'Von Benachrichtigungen abmelden',
    'unsubscribe.confirm': 'Bist du dir sicher?',
    'unsubscribe.yes': 'Ja',
    'unsubscribe.no': 'Nein',
    'unsubscribe.feedback': 'Bitte wähle den Abmelde-Grund aus um deine Abmeldung zu bestätigen:',
    'unsubscribe.enterReason': 'Bitte gib den Grund ein:',
    'unsubscribe.submit': 'Absenden',
    'survey.submit': 'Absenden',
    'unsubscribe.reasonQuantity': 'Zu viele Nachrichten',
    'unsubscribe.reasonContent': 'Uninteressante Nachrichten',
    'unsubscribe.reasonOther': 'Anderer Grund',
    'windowclose.confirm': 'Der Vorgang ist noch nicht abgeschlossen, bist du sicher, dass du ihn abbrechen möchtest?',
    'deny.alertHttps': 'Wenn du doch Benachrichtigungen empfangen möchtest, kannst du mit einem Klick auf das Schloss die Einstellung "Benachrichtigungen" auf "Erlauben" setzen.',
    'deny.alertHttp': 'Wenn du doch Benachrichtigungen empfangen möchtest, kannst du mit einem Klick auf das Info-Symbol die Einstellung "Benachrichtigungen" auf "Erlauben" setzen.',
    'bell.subscribe': 'Für Benachrichtigungen anmelden',
    'bell.subscribed': 'Du bist für Benachrichtigungen angemeldet',
    'bell.unsubscribeSuccess': 'Du wirst keine Benachrichtigungen mehr erhalten',
    'bell.hide': 'Nicht mehr anzeigen',
    'panel.notifications': 'Nachrichten',
    'panel.editTopics': 'Themenbereiche bearbeiten',
    'panel.settings': 'Einstellungen',
    'panel.notificationsEmpty': 'Keine Nachrichten vorhanden.',
    'panel.save': 'Speichern',
    'panel.close': 'Schlie&szlig;en',
    'panel.successfullySaved': 'Erfolgreich gespeichert!',
    'unblock.title': 'Du hast Benachrichtigungen blockiert.<br />Bitte folge diesen Schritten um Benachrichtigungen zu empfangen',
    privacy: 'Datenschutz',
    'chat.emailAddress': 'E-Mail Adresse',
    'chat.name': 'Dein Name',
    'chat.button': 'Button',
    'chat.emailError': 'Bitte gib eine gültige E-Mail Adresse ein.',
    'chat.start': 'Chat starten',
    'chat.enable': 'Chat jetzt aktivieren',
    'chat.enterMessage': 'Bitte Nachricht eingeben',
    'chat.today': 'Heute',
    'chat.yesterday': 'Gestern',
    'chat.dayBefore': 'Vorgestern',
    'chat.sunday': 'Sonntag',
    'chat.monday': 'Montag',
    'chat.tuesday': 'Dienstag',
    'chat.wednesday': 'Mittwoch',
    'chat.thursday': 'Donnerstag',
    'chat.friday': 'Freitag',
    'chat.saturday': 'Samstag',
    'chat.the': 'den',
    'alert.unsupported': 'Dein Browser unterstützt leider keine Benachrichtigungen.',
    'voucherpool.title': 'Dein Gutscheincode',
    'voucherpool.text': 'Klicke hier um deinen Code zu kopieren',
    'voucher.code.copied': 'Gutscheincode wurde in die Zwischenablage kopiert!',
    'story.overlay.text': 'Story öffnen',
    'alert.checkboxRequired': 'Bitte wähle mindestens einen Themenbereich aus',
    'alert.unexpected': 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    'alert.successEmailSend': 'Du erhältst nun eine E-Mail mit einem Link, darüber kannst du deine Einstellungen anpassen.',
    'alert.enterTextAnswer': 'Bitte geben Sie eine Antwort ein',
    'widget.topics': 'Themenbereiche',
    'widget.tags': 'Tags',
    'next.survey': 'Nächste Umfrage',
    'next.surveyQuestion': 'Weiter',
    'enterTextAnswer.survey': 'Geben Sie Ihre Antwort ein'
  },
  'de-formal': {
    'confirm.title': '{0} würde Ihnen gerne Push Nachrichten senden.',
    'confirm.nativeTitle': '{0} möchte Ihnen Benachrichtigungen senden',
    'confirm.info': 'Benachrichtigungen können jederzeit in den Browser Einstellungen deaktiviert werden.',
    'confirm.allow': 'Erlauben',
    'confirm.allowChrome': 'Zulassen',
    'confirm.allowFirefox': 'Benachrichtigungen erlauben',
    'confirm.deny': 'Verbieten',
    'confirm.selectTopics': 'Welche Themen interessieren Sie?',
    'confirm.doubleText': 'Bitte bestätigen Sie 2x mit {0} um fortzufahren',
    'confirm.alreadySubscribed': 'Sie haben sich bereits für alle Themen angemeldet.',
    'confirm.silentPromptInfo': 'Bitte klicken Sie auf die kleine Glocke oben in der Adresszeile und dann auf "Erlauben" um fortzufahren.',
    'confirm.more': 'Mehr…',
    'popup.info': 'Klicken Sie auf {0}, um unsere Push-Benachrichtigungen zu bestätigen',
    'popup.text': 'Erhalten Sie Updates und Angebote von {0}, indem Sie Push-Benachrichtigungen abonnieren.',
    'unsubscribe.title': 'Von Benachrichtigungen abmelden',
    'unsubscribe.confirm': 'Sind Sie sich sicher?',
    'unsubscribe.yes': 'Ja',
    'unsubscribe.no': 'Nein',
    'unsubscribe.feedback': 'Bitte wählen Sie den Abmelde-Grund aus um Ihre Abmeldung zu bestätigen:',
    'unsubscribe.enterReason': 'Bitte geben Sie den Grund ein:',
    'unsubscribe.submit': 'Absenden',
    'survey.submit': 'Absenden',
    'unsubscribe.reasonQuantity': 'Zu viele Nachrichten',
    'unsubscribe.reasonContent': 'Uninteressante Nachrichten',
    'unsubscribe.reasonOther': 'Anderer Grund',
    'windowclose.confirm': 'Der Vorgang ist noch nicht abgeschlossen, sind Sie sich sicher, dass Sie ihn abbrechen möchten?',
    'deny.alertHttps': 'Wenn Sie doch Benachrichtigungen empfangen möchten, können Sie mit einem Klick auf das Schloss die Einstellung "Benachrichtigungen" auf "Erlauben" setzen.',
    'deny.alertHttp': 'Wenn Sie doch Benachrichtigungen empfangen möchten, können Sie mit einem Klick auf das Info-Symbol die Einstellung "Benachrichtigungen" auf "Erlauben" setzen.',
    'bell.subscribe': 'Für Benachrichtigungen anmelden',
    'bell.subscribed': 'Sie sind für Benachrichtigungen angemeldet',
    'bell.unsubscribeSuccess': 'Sie werden keine Benachrichtigungen mehr erhalten',
    'panel.notifications': 'Benachrichtigungen',
    'panel.editTopics': 'Themenbereiche bearbeiten',
    'panel.settings': 'Einstellungen',
    'panel.notificationsEmpty': 'Keine Benachrichtigungen vorhanden.',
    'panel.save': 'Speichern',
    'panel.close': 'Schlie&szlig;en',
    'panel.successfullySaved': 'Erfolgreich gespeichert!',
    'unblock.title': 'Sie haben Benachrichtigungen blockiert.<br />Bitte folgen Sie diesen Schritten um Benachrichtigungen zu empfangen',
    privacy: 'Datenschutz',
    'chat.emailAddress': 'E-Mail Adresse',
    'chat.name': 'Ihr Name',
    'chat.button': 'Button',
    'chat.emailError': 'Bitte geben Sie eine gültige E-Mail Adresse ein.',
    'chat.start': 'Chat starten',
    'chat.enable': 'Chat jetzt aktivieren',
    'chat.enterMessage': 'Bitte Nachricht eingeben',
    'chat.today': 'Heute',
    'chat.yesterday': 'Gestern',
    'chat.dayBefore': 'Vorgestern',
    'chat.sunday': 'Sonntag',
    'chat.monday': 'Montag',
    'chat.tuesday': 'Dienstag',
    'chat.wednesday': 'Mittwoch',
    'chat.thursday': 'Donnerstag',
    'chat.friday': 'Freitag',
    'chat.saturday': 'Samstag',
    'chat.the': 'den',
    'alert.unsupported': 'Ihr Browser unterstützt leider keine Benachrichtigungen.',
    'voucherpool.title': 'Dein Gutscheincode',
    'voucherpool.text': 'Klicken Sie hier um Ihren Code zu kopieren',
    'voucher.code.copied': 'Gutscheincode wurde in die Zwischenablage kopiert!',
    'story.overlay.text': 'Story öffnen',
    'alert.checkboxRequired': 'Bitte wählen Sie mindestens einen Themenbereich aus',
    'alert.unexpected': 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
    'alert.successEmailSend': 'Sie erhalten nun eine E-Mail mit einem Link, darüber können Sie Ihre deine Einstellungen anpassen.',
    'widget.topics': 'Themenbereiche',
    'widget.tags': 'Tags',
    'next.survey': 'Nächste Umfrage',
    'next.surveyQuestion': 'Weiter',
    'enterTextAnswer.survey': 'Geben Sie Ihre Antwort ein'
  }
};
