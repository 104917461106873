export default (
  `@media (min-width: 768px) {
    .cleverpush-confirm-branding {
      bottom: 0 !important;
      left: 0 !important;
    }

    .cleverpush-confirm-buttons {
      max-width: calc(100% - 80px);
      margin-left: auto !important;
    }
  }`
);
