const TCF_VERSION = 2;
const SYNC_PARAMETERS_TIMEOUT = 10000;
const PIANO_ENABLED_WAIT_TIMEOUT = 2000;

const pianoChangeListeners = [];
let pianoEnabled = true;

const getTCFVendors = () => new Promise((resolve) => {
  if (window.UC_UI && window.UC_UI.getTCFVendors) {
    resolve(window.UC_UI.getTCFVendors());
    return;
  }
  __tcfapi('getVendorList', TCF_VERSION, (vendorList, vendorListSuccess) => {
    if (!vendorListSuccess || !vendorList.vendors) {
      resolve(null);
      return;
    }
    const vendors = Object.values(vendorList.vendors);
    resolve(vendors);
  });
});

// Check in UserCentrics + TCF2 if Piano is enabled or disabled
(async () => {
  if (typeof __tcfapi === 'function' && typeof UC_UI === 'object') {
    const getPianoService = () => new Promise((resolve) => {
      getTCFVendors().then((vendors) => {
        if (!vendors) {
          resolve(null);
          return;
        }

        const pianoVendor = vendors.find((vendor) => (
          vendor.name && (vendor.name.toLowerCase().indexOf('cxense') > -1 || vendor.name.toLowerCase().indexOf('piano') > -1)
        ));

        if (!pianoVendor) {
          resolve(null);
          return;
        }

        __tcfapi('addEventListener', TCF_VERSION, (tcData, success) => {
          __tcfapi('removeEventListener', TCF_VERSION, () => {}, tcData.listenerId);

          if (!success || !tcData.vendor || !tcData.vendor.consents) {
            resolve(null);
            return;
          }

          resolve({
            ...pianoVendor,
            consent: { status: tcData.vendor.consents[pianoVendor.id] }
          });
        });
      });
    });

    pianoEnabled = false;
    let pianoService = await getPianoService();
    if (pianoService) {
      pianoEnabled = pianoService.consent.status;
    }

    __tcfapi('addEventListener', TCF_VERSION, async (success) => {
      if (!success) {
        return;
      }
      pianoService = await getPianoService();
      if (pianoService) {
        const pianoEnabledNew = pianoService.consent.status;
        if (pianoEnabledNew !== pianoEnabled) {
          pianoEnabled = pianoEnabledNew;
          pianoChangeListeners.forEach((listener) => typeof listener === 'function' && listener(pianoEnabled));
        }
      }
    });
  }
})();

export const getPianoEnabled = () => pianoEnabled;

export const onPianoEnabledChange = (listener) => pianoChangeListeners.push(listener);

const waitForPianoEnabled = (timeout) => new Promise((resolve) => {
  if (pianoEnabled) {
    return resolve(true);
  }
  Promise.race([
    new Promise((timeoutResolve) => {
      setTimeout(timeoutResolve, timeout);
    }),
    new Promise((enabledChangeResolve) => {
      onPianoEnabledChange(enabledChangeResolve);
    })
  ]).then(resolve);
});

export const getPianoUserData = () => new Promise((resolve) => {
  let resolved = false;
  const pianoTimeout = setTimeout(() => {
    resolved = true;
    resolve({});
  }, SYNC_PARAMETERS_TIMEOUT);

  waitForPianoEnabled(PIANO_ENABLED_WAIT_TIMEOUT).then(() => {
    if (pianoEnabled) {
      // in case piano is enabled in CMP (or not found)
      const cX = window.cX || {};
      cX.callQueue = cX.callQueue || [];
      cX.callQueue.push(['getUserSegmentIds', {
        persistedQueryId: self.CleverPush.config.pianoPublicPersistedId,
        callback: (segments) => {
          if (resolved) {
            return;
          }

          clearTimeout(pianoTimeout);
          resolved = true;

          resolve({
            segments,
          });
        }
      }]);
    } else {
      if (resolved) {
        return;
      }

      clearTimeout(pianoTimeout);
      resolved = true;

      // in case use removed piano from CMP again
      resolve({
        segments: [],
        userId: 'optout'
      });
    }
  });
});

export const getPianoSegmentsHash = async () => {
  const { segments } = await getPianoUserData();
  const segmentsString = JSON.stringify(segments);
  const msgUint8 = new TextEncoder().encode(segmentsString);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};
