import UAParser from 'ua-parser-js';

import translations from '../const/translation';

const DEFAULT_LANG = 'en';

export const detectLanguage = (config) => {
  if (config?.enableTranslationLanguageFromHTML && document.documentElement.lang) {
    return (document.documentElement.lang).substring(0, 2);
  }

  return config?.locale || (navigator.language || navigator.userLanguage || '').substr(0, 2) || DEFAULT_LANG;
};

export function charsetEscape(text) {
  if (!text) {
    return text;
  }
  let newText = text;
  const charset = (document.characterSet || '').toLowerCase();
  if (charset.indexOf('windows-1252') > -1 || charset.indexOf('iso-8859') > -1) {
    try {
      let escapedText = escape(text);
      escapedText = escapedText.replace(new RegExp('%E2%u20AC%u017E', 'g'), '%E2%80%9E');
      escapedText = escapedText.replace(new RegExp('%E2%u20AC%u0153', 'g'), '%E2%80%9C');
      newText = decodeURIComponent(escapedText);
    } catch (error) {
      newText = new TextDecoder('utf-8').decode(Uint8Array.from(text, (x) => x.charCodeAt(0)));
    }
  }
  return newText;
}

export function translate(keyParam, charsetAlreadyEscaped) {
  let lang = detectLanguage((window.CleverPush || { config: {} }).config);
  lang = lang !== 'de-formal' ? lang.substr(0, 2).toLowerCase() : lang;
  if (!translations.hasOwnProperty(lang)) {
    lang = DEFAULT_LANG;
  }

  let key = keyParam;
  if (key === 'confirm.allowBrowser') {
    key = 'confirm.allow';
    const parser = new UAParser();
    if (parser.getBrowser().name === 'Chrome' || parser.getBrowser().name === 'Edge') {
      key = 'confirm.allowChrome';
    } else if (parser.getBrowser().name === 'Firefox') {
      key = 'confirm.allowFirefox';
    }
  } else if (key === 'deny.alert') {
    if (location.protocol === 'https:') {
      key = 'deny.alertHttps';
    } else {
      key = 'deny.alertHttp';
    }
  }

  let trans;
  if (translations[lang].hasOwnProperty(key)) {
    trans = translations[lang][key];
  } else if (translations[DEFAULT_LANG].hasOwnProperty(key)) {
    trans = translations[DEFAULT_LANG][key];
  }

  if (trans) {
    if (!charsetAlreadyEscaped) {
      return charsetEscape(trans);
    }
    return trans;
  }

  return key;
}

String.prototype.formatCleverPush = function () {
  let formatted = this;
  for (const arg in arguments) {
    if (arguments.hasOwnProperty(arg) && typeof formatted.replace === 'function') {
      formatted = formatted.replace(`{${arg}}`, arguments[arg]);
    }
  }
  return formatted;
};
