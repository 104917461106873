import UAParser from 'ua-parser-js';

import { log, logMethodCall } from './utils/debug';
import request from './utils/request';
import { getPianoUserData } from './utils/piano';
import { detectLanguage } from './utils/translate';
import { getApiEndpoint } from './utils/getApiEndpoint';
import { getBrowserName } from './utils/env';

const SYNC_PARAMETERS_TIMEOUT = 3000;
const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
};

const TRACK_EVENTS = {
  CONVERSIONS: 'conversions',
  DELIVERED: 'delivered',
  CLICKED: 'clicked'
};

export default class CleverPushApi {
  constructor(channelId) {
    this.channelId = channelId;
    this.originalChannelId = channelId;
    this.confirmAlertTestId = undefined;
    this.endpoint = getApiEndpoint();
    this.regionEnabled = false;
  }

  setConfirmAlertTestId(id) {
    this.confirmAlertTestId = id;
  }

  request(method, path, data, options = {}) {
    logMethodCall('request', method, path, data);

    const finalOptions = {
      method: HTTP_METHODS.GET,
      headers: {
        Accept: 'application/json',
      },
      ...options,
    };
    if (method) {
      finalOptions.method = method;
    }
    if (data) {
      if (this.authorizerToken) {
        data.subscriptionToken = this.authorizerToken;
      }
      finalOptions.body = JSON.stringify({ sdkVersion: typeof VERSION !== 'undefined' ? VERSION : undefined, ...data });
      finalOptions.headers['Content-Type'] = 'application/json';
    }

    return request(this.endpoint + path, finalOptions);
  }

  getChannelConfig(confirmAlertTestsEnabled = false, bypassCache = false) {
    let url = `/channel/${this.channelId}/config`;
    if (confirmAlertTestsEnabled) {
      url += '?confirmAlertTestsEnabled=true';

      const parser = new UAParser(navigator.userAgent);
      const platform = parser.getOS();
      if (platform.name) {
        url += `&platformName=${platform.name}`;
      }
    }

    if (bypassCache) {
      url += '?bypassCache=true';
    }

    return this.request(HTTP_METHODS.GET, url, null);
  }

  tagSubscription(subscriptionId, tagId) {
    const payload = {
      channelId: this.channelId,
      tagId,
      subscriptionId
    };
    return this.request(HTTP_METHODS.POST, '/subscription/tag', payload);
  }

  notificationUrlClicked(notificationId, url) {
    const payload = { url };
    const endpoint = `/channel/${this.channelId}/notification/${notificationId}/chat-clicked`;
    return this.request(HTTP_METHODS.POST, endpoint, payload);
  }

  untagSubscription(subscriptionId, tagId) {
    const payload = {
      channelId: this.channelId,
      tagId,
      subscriptionId
    };
    return this.request(HTTP_METHODS.POST, '/subscription/untag', payload);
  }

  setSubscriptionAttribute(subscriptionId, attributeId, value) {
    const payload = {
      channelId: this.channelId,
      attributeId,
      value,
      subscriptionId
    };
    return this.request(HTTP_METHODS.POST, '/subscription/attribute', payload);
  }

  pushSubscriptionAttributeValue(subscriptionId, attributeId, value) {
    const payload = {
      channelId: this.channelId,
      attributeId,
      value,
      subscriptionId
    };
    return this.request(HTTP_METHODS.POST, '/subscription/attribute/push-value', payload);
  }

  pullSubscriptionAttributeValue(subscriptionId, attributeId, value) {
    const payload = {
      channelId: this.channelId,
      attributeId,
      value,
      subscriptionId
    };
    return this.request(HTTP_METHODS.POST, '/subscription/attribute/pull-value', payload);
  }

  triggerFollowUpEvent(subscriptionId, campaignId, notificationId, name, parameters, fromNotification, bypassInactive) {
    const data = {
      channelId: this.channelId,
      subscriptionId,
      campaignId,
      notificationId,
      name,
      parameters,
      fromNotification,
      bypassInactive
    };

    if (name === 'triggerArticleBounce' && typeof navigator.sendBeacon === 'function') {
      const blob = new Blob([JSON.stringify(data)], { type: 'application/x-www-form-urlencoded' });
      return navigator.sendBeacon(`${this.endpoint}/subscription/event`, blob);
    }

    return this.request(HTTP_METHODS.POST, '/subscription/event', data);
  }

  triggerJourneyEvent(subscriptionId, journeyId, notificationId, fromNotification, name, type, parameters) {
    const data = {
      channelId: this.channelId,
      subscriptionId,
      journeyId,
      notificationId,
      fromNotification,
      type,
      name,
      parameters,
    };

    if (name === 'triggerArticleBounce' && typeof navigator.sendBeacon === 'function') {
      const blob = new Blob([JSON.stringify(data)], { type: 'application/x-www-form-urlencoded' });
      return navigator.sendBeacon(`${this.endpoint}/subscription/journey-event`, blob);
    }

    return this.request(HTTP_METHODS.POST, '/subscription/journey-event', data);
  }

  trackConversion(subscriptionId, notificationId, eventId, properties, minutes, isNotificationDelivered = false, trackOnlyMinutesConversion) {
    const requestBody = {
      channelId: this.channelId,
      subscriptionId,
      notificationId,
      eventId,
      properties,
      trackOnlyMinutesConversion,
    };

    if (typeof minutes !== 'undefined') {
      requestBody.minutes = minutes;
    }

    if (isNotificationDelivered) {
      requestBody.isNotificationDelivered = isNotificationDelivered;
    }

    return this.request(HTTP_METHODS.POST, '/subscription/conversion', requestBody);
  }

  trackWidget(widgetId, type, widgetTestId, platform) {
    return this.request(HTTP_METHODS.POST, `/channel/${this.channelId}/widget/${type}`, {
      widgetChannelId: this.channelId,
      widgetId,
      widgetTestId,
      platform
    });
  }

  trackPanel(notificationId, type) {
    return this.request(HTTP_METHODS.POST, `/channel/${this.channelId}/panel/${type}`, {
      channelId: this.channelId,
      notificationId
    });
  }

  trackBounce(params) {
    const data = { channelId: this.channelId, ...params || {} };

    const parser = new UAParser(navigator.userAgent);
    const browser = parser.getBrowser();
    const platform = parser.getOS();

    data.platformName = platform.name || undefined;
    data.browserType = browser.name || undefined;

    if (typeof navigator.sendBeacon === 'function') {
      try {
        logMethodCall('sendBeacon', HTTP_METHODS.POST, `${this.endpoint}/notification/bounced`, data);
      } catch (error) {
        log.error(error);
      }

      const blob = new Blob([JSON.stringify(data)], { type: 'application/x-www-form-urlencoded' });
      return navigator.sendBeacon(`${this.endpoint}/notification/bounced`, blob);
    }

    return this.request(HTTP_METHODS.POST, '/notification/bounced', data);
  }

  getSyncParameters(noPromise) {
    logMethodCall('getSyncParameters');

    const parser = new UAParser(navigator.userAgent);
    const browser = parser.getBrowser();
    const platform = parser.getOS();

    const browserVersionMain = (browser.version || '').split('.')[0];
    const platformVersionMain = (platform.version || '').split('.')[0];

    let params = {
      channelId: this.channelId,
      browserType: browser.name || undefined,
      browserVersion: browserVersionMain || browser.version || undefined,
      platformName: platform.name || undefined,
      platformVersion: platformVersionMain || platform.version || undefined
    };

    const pathname = location.pathname;
    if (pathname) {
      params.pathname = pathname;
    }

    try {
      if (typeof sessionStorage !== 'undefined') {
        const referrer = sessionStorage.getItem('cleverpush-referrer') || document.referrer.split('?')[0];
        if (referrer) {
          params.referrer = referrer;
        }

        const referralSubscriptionId = sessionStorage.getItem('cleverpush-referral-subscription-id');
        if (referralSubscriptionId) {
          params.referralSubscriptionId = referralSubscriptionId;
        }
      }

      if (typeof localStorage !== 'undefined') {
        const visits = localStorage.getItem('cleverpush-visits') || 0;
        if (!isNaN(visits)) {
          params.visits = `${visits}`;
        }

        if (localStorage.getItem('subscription-status') === 'was-denied' || localStorage.getItem('subscription-status') === 'denied') {
          params.wasDenied = true;
        }
      }
    } catch (ignored) { }

    const language = detectLanguage(self?.CleverPush?.config);
    if (language) {
      params.language = language;
    }

    if (this.confirmAlertTestId) {
      params.confirmAlertTestId = this.confirmAlertTestId;
    }

    if (this.testSubscription) {
      params.testSubscription = this.testSubscription;
    }

    if (typeof noPromise !== 'undefined' && noPromise) {
      return params;
    }

    const promises = [];

    if (self.CleverPush?.config?.geoipApiEndpoint) {
      const geoipPromise = new Promise((resolve) => {
        request(self.CleverPush?.config?.geoipApiEndpoint, {
          mode: 'cors',
          timeout: SYNC_PARAMETERS_TIMEOUT
        }).then((json) => {
          params = Object.assign(params, json);
          resolve();
        }).catch((err) => {
          log.error(err);
          resolve();
        });
      });
      promises.push(geoipPromise);
    }

    if (
      self.CleverPush
      && self.CleverPush.config
      && self.CleverPush.config.pianoEnabled
      && self.CleverPush.config.pianoPublicPersistedId
    ) {
      try {
        const pianoPromise = new Promise((resolve) => {
          getPianoUserData().then(({ segments }) => {
            params.pianoSegments = segments;
            resolve();
          }).catch(resolve);
        });
        promises.push(pianoPromise);
      } catch (error) {
        log.info('Error getting Piano Segments', error);
      }
    }

    return Promise.all(promises).then(() => Promise.resolve(params));
  }

  syncSubscription(subscription, topics, tags, optionalParams) {
    logMethodCall('syncSubscription', subscription, topics);

    if (!subscription) {
      return Promise.reject();
    }

    let params = typeof optionalParams === 'object' ? optionalParams : {};

    return this.getSyncParameters().then((paramsParam) => {
      params = Object.assign(params, paramsParam);
      const browserVersionMain = parseInt(params.browserVersion, 10);

      if (!this.regionEnabled) {
        delete params.region;
      }

      if (typeof topics === 'object' && topics && !isNaN(topics.length)) {
        let topicsVersion = 0;
        try {
          const topicsVersionStr = localStorage.getItem('cleverpush-topics-version');
          if (topicsVersionStr && !isNaN(topicsVersionStr)) {
            topicsVersion = parseInt(topicsVersionStr, 10);
          }
        } catch (ignored) { }
        topicsVersion += 1;

        params.topics = topics;
        params.topicsVersion = topicsVersion;
      }
      if (typeof tags === 'object' && tags && tags.length) {
        params.tags = tags;
      }

      if (params.existingPermission) {
        // do not track existing permissions in confirm alert tests
        delete params.confirmAlertTestId;
      }

      if (this.fromBellWidget) {
        params.fromBellWidget = this.fromBellWidget;
      }

      if (this.widgetId) {
        params.widgetId = this.widgetId;
        params.widgetChannelId = this.widgetChannelId || params.channelId;
      }

      if (this.widgetTestId) {
        params.widgetTestId = this.widgetTestId;
      }

      if (subscription.facebookUserRef) {
        params.facebookUserRef = subscription.facebookUserRef;

        if (typeof self !== 'undefined' && self.CleverPush && self.CleverPush.config && self.CleverPush.config.multiChannels && self.CleverPush.config.multiChannels.facebookChannel._id !== params.channelId) {
          params.channelId = self.CleverPush.config.multiChannels.facebookChannel._id;
        }
      }

      if (params.browserType === 'Safari' && typeof subscription === 'object' && subscription.deviceToken) {
        // sync with APN token
        params.apnsToken = subscription.deviceToken;
      } else if (typeof subscription === 'string') {
        // sync with subscriptionId
        params.subscriptionId = subscription;
      } else if (params.browserType === 'Chrome' && browserVersionMain >= 42 && browserVersionMain < 50) {
        // without encryption (old GCM)
        params.endpoint = subscription.endpoint;
      } else {
        // with encryption
        params.endpoint = subscription.endpoint;

        if (typeof subscription.getKey !== 'undefined') {
          const key = subscription.getKey ? subscription.getKey('p256dh') : null;
          let auth = null;
          if (params.browserType !== 'Firefox' || (params.browserType === 'Firefox' && browserVersionMain >= 46)) {
            auth = subscription.getKey ? subscription.getKey('auth') : null;
          }
          if (key) {
            params.publicKey = btoa(String.fromCharCode.apply(null, new Uint8Array(key)));
          }
          if (auth) {
            params.authSecret = btoa(String.fromCharCode.apply(null, new Uint8Array(auth)));
          }
        }
      }

      return new Promise((resolve, reject) => this.request(HTTP_METHODS.POST, `/subscription/sync/${this.channelId}`, params)
        .then((json) => {
          if (json && json.id) {
            resolve(json);
          } else if (json && json.error) {
            reject(json.error);
          } else {
            reject();
          }
        }).catch((err) => {
          if (err.reason) {
            reject(err.reason);
          } else if (err.status) {
            reject(err.status);
          } else {
            reject(err);
          }
        }));
    });
  }

  unsubscribe(subscriptionId) {
    return new Promise((resolve) => {
      this.request(HTTP_METHODS.POST, '/subscription/unsubscribe', {
        subscriptionId,
        channelId: this.channelId
      }).then(resolve).catch(resolve);
    });
  }

  unsubscribeFeedback(type, text = '') {
    return new Promise((resolve) => {
      this.request(HTTP_METHODS.POST, '/subscription/unsubscribe/feedback', {
        type,
        text,
        channelId: this.channelId
      }).then(resolve).catch(resolve);
    });
  }

  startSession(subscriptionId, data) {
    return new Promise((resolve) => {
      this.request(HTTP_METHODS.POST, '/subscription/session/start', {
        subscriptionId,
        channelId: this.channelId,
        ...data || {}
      }).then(resolve).catch(resolve);
    });
  }

  endSession(subscriptionId, options) {
    const blob = new Blob([JSON.stringify({ subscriptionId, channelId: this.channelId, ...options })], { type: 'application/x-www-form-urlencoded' });
    return navigator.sendBeacon(`${this.endpoint}/subscription/session/end`, blob);
  }

  trackSessionImpression(subscriptionId, notificationId) {
    return new Promise((resolve) => {
      this.request(HTTP_METHODS.POST, '/subscription/session/impression', {
        subscriptionId,
        notificationId,
        channelId: this.channelId
      }).then(resolve).catch(resolve);
    });
  }

  confirmAlertShown(existingPermission, type = 'confirm-alert') {
    let report = true;
    const localStorageKey = `cleverpush-${type}-reported`;
    const reportMuteHours = 2;

    const confirmAlertLastReported = sessionStorage.getItem(localStorageKey);
    if (confirmAlertLastReported) {
      const confirmAlertLastReportedInt = parseInt(confirmAlertLastReported, 10);
      if (!isNaN(confirmAlertLastReportedInt)) {
        report = (confirmAlertLastReportedInt + (1000 * 60 * 60 * reportMuteHours)) < Date.now();
      }
    }

    if (report) {
      sessionStorage.setItem(localStorageKey, Date.now());

      const data = {
        channelId: this.channelId,
        confirmAlertTestId: this.confirmAlertTestId
      };

      const parser = new UAParser(navigator.userAgent);
      const browser = parser.getBrowser();
      const platform = parser.getOS();

      data.platformName = platform.name || undefined;
      data.browserType = browser.name || undefined;

      if (typeof existingPermission !== 'undefined' && existingPermission) {
        data.existingPermission = true;
        // do not track existing permissions in confirm alert tests
        delete data.confirmAlertTestId;
      }

      if (typeof window !== 'undefined' && window.CleverPush && window.CleverPush.config.trackOptInPathnames) {
        const pathname = location.pathname;
        if (pathname) {
          data.pathname = pathname;
        }
      }

      if (typeof window !== 'undefined' && window.CleverPush && window.CleverPush.config.filterId) {
        const filterId = window.CleverPush.config.filterId;
        data.filterId = filterId;
      }

      if (this.widgetId) {
        data.widgetId = this.widgetId;
      }

      if (typeof window !== 'undefined' && window.CleverPush && window.CleverPush.config.trackOptInReferrers) {
        try {
          if (typeof sessionStorage !== 'undefined') {
            const referrer = sessionStorage.getItem('cleverpush-referrer') || document.referrer.split('?')[0];
            if (referrer) {
              data.referrer = referrer;
            }
          }
        } catch (ignored) { }
      }

      if (typeof localStorage !== 'undefined' && (localStorage.getItem('subscription-status') === 'was-denied' || localStorage.getItem('subscription-status') === 'denied')) {
        data.wasDenied = true;
      }

      return this.request(HTTP_METHODS.POST, `/channel/${type}`, data);
    }
    return Promise.resolve();
  }

  trackOptInVisitor() {
    const params = {
      channelId: this.channelId,
      confirmAlertTestId: this.confirmAlertTestId
    };

    if (this.widgetId) {
      params.widgetId = this.widgetId;
    }

    return this.request(HTTP_METHODS.POST, '/channel/optin-visitor', params);
  }

  confirmAlertDenied() {
    const params = {
      channelId: this.channelId,
      confirmAlertTestId: this.confirmAlertTestId
    };

    if (this.widgetId) {
      params.widgetId = this.widgetId;
    }

    if (params.existingPermission) {
      // do not track existing permissions in confirm alert tests
      delete params.confirmAlertTestId;
    }

    return this.request(HTTP_METHODS.POST, '/channel/blocked-optin-request', params);
  }

  generateWalletPass(walletPassId, options) {
    return new Promise((resolve, reject) => {
      this.request(HTTP_METHODS.POST, `/channel/${this.channelId}/wallet-pass/${walletPassId}/subscribe`, { walletPassId, ...options }).then(resolve).catch(reject);
    });
  }

  trackWebBannerEvent(event, bannerId, elementId, testId) {
    const path = `/web-banner/event/${event}`;
    const data = {
      bannerId,
      elementId,
      testId,
      channelId: this.channelId
    };

    if (event === TRACK_EVENTS.CONVERSIONS) {
      const shownWebBanners = JSON.parse(sessionStorage.getItem('cleverpush-shown-web-banners')) || {};
      data.shownWebBanners = shownWebBanners;
    }

    if (event === TRACK_EVENTS.DELIVERED || event === TRACK_EVENTS.CLICKED) {
      data.browser = getBrowserName();
    }

    if (typeof navigator.sendBeacon === 'function') {
      const blob = new Blob([JSON.stringify(data)], { type: 'application/x-www-form-urlencoded' });
      return navigator.sendBeacon(`${this.endpoint}${path}`, blob);
    }

    return this.request(HTTP_METHODS.POST, path, data);
  }

  trackAppDownloadBannerEvent(event) {
    const path = `/app-download-banner/event/${event}`;
    const data = { channelId: this.originalChannelId || this.channelId };

    if (typeof navigator.sendBeacon === 'function') {
      const blob = new Blob([JSON.stringify(data)], { type: 'application/x-www-form-urlencoded' });
      return navigator.sendBeacon(`${this.endpoint}${path}`, blob);
    }

    return this.request(HTTP_METHODS.POST, path, data);
  }

  getSocialFeedWidget(widgetId) {
    const path = `/channel/${this.channelId}/social-feed-widget/${widgetId}`;
    return this.request(HTTP_METHODS.GET, path);
  }

  getWidget(widgetId) {
    const url = `/widgets/${widgetId}`;
    return this.request(HTTP_METHODS.GET, url);
  }
}
