import { log, logMethodCall } from '../utils/debug';
import {
  isAndroid,
  getScrollPercentage
} from '../utils/env';
import CleverPushError from '../error/CleverPushError';
import { detectPrompt } from '../utils/detect';
import {
  TRUE_STRING,
  TCF_VERSION,
  TCF_MAX_RETRY_COUNT,
  TCF_RETRY_DELAY_MS,
  PREVENT_OPTIN_OPEN,
  CLEVERPUSH_ALERT_SHOWN
} from '../const/common';
import Event from '../const/event';

export function triggerOptIn(firstArg, secondArg) {
  logMethodCall('triggerOptIn', firstArg, secondArg);
  const dontShowOptIn = Boolean(sessionStorage.getItem(PREVENT_OPTIN_OPEN));
  if (dontShowOptIn) {
    return null;
  }
  const callbackParam = typeof secondArg === 'function' ? secondArg : firstArg;
  const forceSubscribe = typeof firstArg === 'boolean' && firstArg;

  const callback = typeof callbackParam === 'function' ? callbackParam : (err) => {
    if (err) {
      if (err.reason === 'unsubscribed' || err.reason === 'subscribed') {
        log.info(err.message || err);
      } if (err.reason === 'unsupported-browser' || err.reason === 'private-mode' || err.reason === 'already-initialized' || err.reason === 'init-already-called' || err.reason === 'unsubscribed') {
        log.warn(err.message || err);
      } else {
        log.error(err.message || err);
      }
    }
  };

  const checkIfCanSubscribe = Promise.resolve(forceSubscribe ? true : this.subscriptionManager.canSubscribe());

  return this.waitForConfirmAvailable().then(() => {
    const successCallback = () => {
      this.subscriptionManager.getSubscriptionId().then((subscriptionId) => {
        callback(false, subscriptionId);
      });
    };

    checkIfCanSubscribe.then(async (canSubscribe) => {
      if (canSubscribe === true) {
        const notificationPermission = await this.subscriptionManager.getNotificationPermission();

        if (this.confirm && (this.config.showConfirmAlert || !this.config.ownDomain || !(window.parent.location.protocol === 'https:' || window.top.location.hostname === 'localhost'))) {
          log.debug('this.confirm.show 1');
          this.confirm.setAutoHideBackdrop(notificationPermission === 'granted' || isAndroid());
          this.confirm.show((err) => {
            if (err) {
              callback(err);
            } else {
              successCallback();
            }
          });
        } else if (!this.subscriptionManager.isSubscribing) {
          // only show backdrop if notification permission is not granted
          // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          let showBackdrop = notificationPermission === 'default' || this.config.env === 'PREVIEW'; // && !isSafari;
          log.debug('showBackdrop', showBackdrop);

          const subscribe = (subCallback) => {
            if (this.config.env === 'PREVIEW') {
              // don't subscribe in preview mode
              return;
            }

            this.subscriptionManager.subscribe().then((permission) => {
              if (typeof subCallback === 'function') {
                subCallback();
              }

              if (this.confirm && showBackdrop) {
                this.confirm.hideBackdrop(permission);
              }

              if (permission === 'denied') {
                if (this.confirm && this.browserType !== 'safari') {
                  this.confirm.showDenyAlert();
                }
                callback(new CleverPushError('Permission for notifications was denied', permission));
              } else {
                successCallback();
              }
            }).catch((err) => {
              if (typeof subCallback === 'function') {
                subCallback();
              }

              if (this.confirm && showBackdrop) {
                this.confirm.hideBackdrop('denied');
              }
              callback(err);
            });
          };

          if (this.confirm && showBackdrop) {
            detectPrompt({
              subscriptionManager: this.subscriptionManager,
              config: this.config,
              subscribe,
              showBackdrop: () => {
                this.confirm.showBackdrop();
              },
              setShowBackdrop: (backdrop) => {
                showBackdrop = backdrop;
              }
            });
          } else {
            subscribe();
          }
        }

        const existingPermission = notificationPermission === 'granted';
        this.subscriptionManager.existingPermission = existingPermission;

        if (!this.config.noExistingPermissionOptIns || !existingPermission) {
          if (!this.optInVisitorTracked) {
            this.optInVisitorTracked = true;
            this.api.trackOptInVisitor();
          }

          this.api.confirmAlertShown(this.subscriptionManager.existingPermission);
          this.trigger(Event.OPTIN_SHOWN);
        }
      } else {
        callback(new CleverPushError('Can not subscribe because of an unknown error', 'cannot-subscribe'));
      }
    }).catch((err) => {
      if (err && err.reason === 'subscribed') {
        // don't throw an error if user is already subscribed
        log.debug('running successCallback()');
        successCallback();

        // TODO: check if err.reason is 'unsubscribed' and teach user how to re-subscribe
      } else {
        callback(err);
      }
    });
  }).catch((error) => {
    callback(error);
  });
}

export function autoTriggerOptIn(context) {
  // we need to cache this here, because if called inside checkVisits the value will be too high.
  const storageManagerVisits = context.subscriptionManager.storageManager.getVisits();

  const dontShowOptIn = Boolean(sessionStorage.getItem(PREVENT_OPTIN_OPEN));
  if (dontShowOptIn) {
    return;
  }
  let optInTriggered = false;
  const optIn = () => {
    if (optInTriggered) {
      return;
    }
    optInTriggered = true;

    const checkVisits = () => {
      if (storageManagerVisits >= context.config.alertMinimumVisits) {
        setTimeout(() => {
          if (context.config.preventDuplicateOptIns && context.config.androidPackageName && 'getInstalledRelatedApps' in navigator) {
            navigator.getInstalledRelatedApps().then((relatedApps) => {
              let foundApp = false;
              if (relatedApps) {
                relatedApps.forEach((app) => {
                  if (app.id === context.config.androidPackageName) {
                    foundApp = true;
                  }
                });
              }
              if (!foundApp) {
                sessionStorage.setItem(CLEVERPUSH_ALERT_SHOWN, TRUE_STRING);
                context.triggerOptIn();
              }
            });
          } else {
            sessionStorage.setItem(CLEVERPUSH_ALERT_SHOWN, TRUE_STRING);
            context.triggerOptIn();
          }
        }, context.config.alertTimeout);

        if (!context.optInVisitorTracked) {
          context.subscriptionManager.canSubscribe().then((canSubscribe) => {
            if (canSubscribe === true && !context.optInVisitorTracked) {
              context.optInVisitorTracked = true;
              context.api.trackOptInVisitor();
            }
          }).catch(() => {
            // ignored
          });
        }
      }
    };

    if (context.config.alertOncePerSession) {
      if (sessionStorage.getItem(CLEVERPUSH_ALERT_SHOWN) !== TRUE_STRING) {
        checkVisits();
      }
    } else {
      checkVisits();
    }
  };

  const cmpReady = () => {
    if (window.UC_UI_SUPPRESS_CMP_DISPLAY) {
      return window.UC_UI && window.UC_UI.isInitialized();
    }
    return typeof __tcfapi === 'function';
  };

  const optInReady = (retryCount = 0) => {
    if (context.config.optInWaitForTcfDecision && cmpReady()) {
      let tcfOptInFired = false;

      const tcfOptIn = (tcData) => {
        log.debug('tcfOptIn tcData', tcData);
        if (context.config.tcfRequireVendorConsent && context.config.tcfVendorId) {
          if (tcData?.vendor?.consents && tcData.vendor?.consents[context.config.tcfVendorId]) {
            optIn();
          } else {
            __tcfapi('getCustomVendorConsents', TCF_VERSION, (vendorConsents) => {
              if (vendorConsents && vendorConsents.grants && vendorConsents?.grants[context.config.tcfVendorId] && vendorConsents?.grants[context.config.tcfVendorId].vendorGrant) {
                tcfOptInFired = true;
                log.debug('__tcfapi has vendor consent, starting opt in', vendorConsents);
                optIn();
              } else {
                log.debug('__tcfapi no vendor consent!', vendorConsents);
              }
            });
          }
        } else {
          tcfOptInFired = true;
          optIn();
        }
      };

      const checkTcData = () => {
        __tcfapi('addEventListener', TCF_VERSION, (tcData, success) => {
          __tcfapi('removeEventListener', TCF_VERSION, () => { }, tcData.listenerId);

          log.debug('__tcfapi addEventListener', tcData, success);

          if (!tcfOptInFired && success && tcData.tcString && tcData.eventStatus !== 'cmpuishown') {
            tcfOptIn(tcData);
          }
        });
      };

      if (context.config.tcfWaitForFunction) {
        const isTcfFunctionAllowed = () => {
          try {
            return !!eval(context.config.tcfWaitForFunction);
          } catch (error) {
            log.debug(error);
          }
          return false;
        };

        setTimeout(() => {
          if (isTcfFunctionAllowed()) {
            checkTcData();
            return;
          }

          let tcfFunctionCompleted = false;
          __tcfapi('addEventListener', TCF_VERSION, () => {
            if (tcfFunctionCompleted) {
              return;
            }
            setTimeout(() => {
              if (isTcfFunctionAllowed()) {
                tcfFunctionCompleted = true;
                checkTcData();
              }
            }, 500);
          });
        }, 500);
      } else {
        checkTcData();
      }
    } else if (context.config.optInWaitForTcfDecision) {
      // we will now retry 30 x in 500ms intervals (= 15 seconds) to find it
      if (retryCount < TCF_MAX_RETRY_COUNT) {
        log.debug('__tcfapi not found. retrying…');
        setTimeout(() => optInReady(retryCount + 1), TCF_RETRY_DELAY_MS);
      } else {
        log.debug('__tcfapi not found');
        optIn();
      }
    } else {
      optIn();
    }
  };

  // automatically trigger opt-in, if enabled
  if (context.config.autoRegister) {
    if (context.config.env === 'PREVIEW') {
      context.triggerOptIn();
    } else {
      if (!isNaN(context.config.alertScrollPercentage) && context.config.alertScrollPercentage > 0) {
        const checkScroll = () => {
          if (getScrollPercentage() >= context.config.alertScrollPercentage) {
            window.removeEventListener('scroll', checkScroll);
            optInReady();
          }
        };
        window.addEventListener('scroll', checkScroll);
        // initial check
        checkScroll();
      } else {
        optInReady();
      }

      if (context.config.alertMinimumVisits && context.config.alertMinimumVisits > 0) {
        // only increment visits if we need to (for triggering opt-in)
        context.subscriptionManager.storageManager.incrementVisits();
      }
    }
  }

  if (context.config.showConfirmAlert && context.config.disableConfirmAlertSearchTraffic) {
    if (context.referrer && /google|bing|yahoo|baidu|duckduckgo|wolframalpha/.test(context.referrer)) {
      context.config.showConfirmAlert = false;
    }
  }

  context.on(Event.PERMISSION_RE_GRANTED, () => {
    if (context.config.autoRegister) {
      optInReady();
    }
  });
}
